import request from '@/utils/request'

export function GetRisLevels() {
  return request({
    url: '/api/RiskLevel/GetRiskLevels',
    method: 'get',
  })
}

export function GetRiskLevelById(data) {
  return request({
    url: `/api/RiskLevel/GetRiskLevelById/${data}`,
    method: 'get',
  })
}
