import request from '@/utils/request'

export function GetImportance() {
  return request({
    url: '/api/ImportanceLevel/GetImportanceLevels',
    method: 'get',
  })
}

export function GetImportanceId(data) {
  return request({
    url: `/api/ImportanceLevel/GetImportanceLevelById/${data}`,
    method: 'get',
  })
}
