<template>
  <div>
    <b-form>
      <b-container>
        <!-- Tahsil Seviyesi -->
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Tahsil Seviyesi</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="EducationLevel"
              v-model="educationLevel"
              label="educationLevelDescription"
              :options="optionTahsil"
            />
          </b-col>
          <b-col
            cols="6"
            md="2"
          >
            <label>Gereklilik Seviyesi </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="EducationImportanceLevel"
              v-model="staffQualification.educationImportanceLevel"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </b-col>
        </b-row>
        <!-- Meslek Lisesi -->
        <b-row
          v-if="educationLevel.educationLevelId===6"
          class="mb-1"
        >
          <b-col
            cols="6"
            md="2"
          >
            <label>Meslek Lisesi</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="VocationHigSchool"
              v-model="staffQualification.vocationalHighSchoolDescription"
              label="vocationalHighSchoolDepartmentName"
              :options="optionEML"
              :reduce="item => item.vocationalHighSchoolDepartmentName"
              multiple
            />
          </b-col>
          <b-col
            cols="6"
            md="2"
          >
            <label>Gereklilik Seviyesi</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="VocationHigSchoolImportanceLevel"
              v-model="staffQualification.vocationalHighSchoolImportanceLevel"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </b-col>
        </b-row>
        <!-- Meslek Y.O. -->
        <b-row
          v-if="educationLevel.educationLevelId===7"
          class="mb-1"
        >
          <b-col
            cols="6"
            md="2"
          >
            <label>Meslek Yüksek Okulu</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="VocationCollege"
              v-model="staffQualification.vocationalCollegeDescription"
              label="associateDegreeName"
              :options="optionMYO"
              :reduce="item => item.associateDegreeName"
              multiple
            />
          </b-col>
          <b-col
            cols="6"
            md="2"
          >
            <label>Gereklilik Seviyesi</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="VocationCollegeImportanceLevel"
              v-model="staffQualification.vocationalCollegeImportanceLevel"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </b-col>
        </b-row>
        <!-- Lisans -->
        <b-row
          v-if="educationLevel.educationLevelId===8"
          class="mb-1"
        >
          <b-col
            cols="6"
            md="2"
          >
            <label>Üniversite</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="University"
              v-model="staffQualification.universityDescription"
              label="bachelorDegreeName"
              :options="optionLisans"
              :reduce="item => item.bachelorDegreeName"
              multiple
            />
          </b-col>
          <b-col
            cols="6"
            md="2"
          >
            <label>Gereklilik Seviyesi</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="UniversityImportanceLevel"
              v-model="staffQualification.universityImportanceLevel"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </b-col>
        </b-row>
        <!-- Master -->
        <b-row
          v-if="educationLevel.educationLevelId===9"
          class="mb-1"
        >
          <b-col
            cols="6"
            md="2"
          >
            <label>Lisansüstü</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="Postgraduate"
              v-model="staffQualification.postgraduateDescription"
              label="postgraduateName"
              :options="optionMaster"
              :reduce="item => item.postgraduateName"
              multiple
            />
          </b-col>
          <b-col
            cols="6"
            md="2"
          >
            <label>Gereklilik Seviyesi</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="PostgraduateImportanceLevel"
              v-model="staffQualification.postgraduateImportanceLevel"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </b-col>
        </b-row>
      </b-container>
      <!--  Tecrübe YILI  -->
      <table
        class="table table-bordered table-responsive-sm"
      >
        <tr class="mb-1 mt-1 border p-1 align-middle">
          <td class="col-3">
            <label>Toplam Tecrübe Yıl</label>
          </td>
          <td class="col-5"><v-select
            id="totalYearsOfExperience"
            v-model="staffQualification.yearsOfExperience"
            label="experienceYearTime"
            :options="optionYear"
          />
          </td>
          <td class="col-4">
            <v-select
              id="totalYearsOfExperienceImportanceLevel"
              v-model="staffQualification.yearsOfExperienceImportanceLevel"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
      </table>
      <!--Bilgisayar -->
      <table
        class="table table-bordered table-responsive-sm"
      >
        <tr>
          <td
            rowspan="6"
            class="col-3"
          >
            Bilgisayar Bilgisi
          </td>
        </tr>
        <tr>
          <td class="col-5">
            <v-select
              id="computerKnowledgeName1"
              v-model="staffRequestComputer.computerDescription1"
              label="computerKnowledgeName"
              :options="optionComp"
              :reduce="item => item.computerKnowledgeName"
            />
          </td>
          <td class="col-4">
            <v-select
              id="Comp1Gerek"
              v-model="staffRequestComputer.computerImportanceLevel1"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="computerKnowledgeName2"
              v-model="staffRequestComputer.computerDescription2"
              label="computerKnowledgeName"
              :options="optionComp"
              :reduce="item => item.computerKnowledgeName"
            />
          </td>
          <td>
            <v-select
              id="Comp2Gerek"
              v-model="staffRequestComputer.computerImportanceLevel2"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="computerKnowledgeName3"
              v-model="staffRequestComputer.computerDescription3"
              label="computerKnowledgeName"
              :options="optionComp"
              :reduce="item => item.computerKnowledgeName"
            />
          </td>
          <td>
            <v-select
              id="Comp3Gerek"
              v-model="staffRequestComputer.computerImportanceLevel3"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="computerKnowledgeName4"
              v-model="staffRequestComputer.computerDescription4"
              label="computerKnowledgeName"
              :options="optionComp"
              :reduce="item => item.computerKnowledgeName"
            />
          </td>
          <td>
            <v-select
              id="Comp4Gerek"
              v-model="staffRequestComputer.computerImportanceLevel4"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="computerKnowledgeName5"
              v-model="staffRequestComputer.computerDescription5"
              label="computerKnowledgeName"
              :options="optionComp"
              :reduce="item => item.computerKnowledgeName"
            />
          </td>
          <td>
            <v-select
              id="Comp5Gerek"
              v-model="staffRequestComputer.computerImportanceLevel5"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
      </table>
      <!-- Yabancı Dil -->
      <table
        class="table table-bordered table-responsive-sm"
      >
        <tr>
          <td
            rowspan="6"
            class="col-3"
          >
            Yabancı Dil Bilgisi
          </td>
        </tr>
        <tr>
          <td class="col-5">
            <v-select
              id="Lang1"
              v-model="staffRequestForeignLanguage.languageDescription1"
              label="languageName"
              :options="optionLang"
              :reduce="item => item.languageName"
            />
          </td>
          <td class="col-4">
            <v-select
              id="Lang1Gerek"
              v-model="staffRequestForeignLanguage.languageImportanceLevel1"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="Lang2"
              v-model="staffRequestForeignLanguage.languageDescription2"
              label="languageName"
              :options="optionLang"
              :reduce="item => item.languageName"
            />
          </td>
          <td>
            <v-select
              id="Lang2Gerek"
              v-model="staffRequestForeignLanguage.languageImportanceLevel2"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="Lang3"
              v-model="staffRequestForeignLanguage.languageDescription3"
              label="languageName"
              :options="optionLang"
              :reduce="item => item.languageName"
            />
          </td>
          <td>
            <v-select
              id="Lang3Gerek"
              v-model="staffRequestForeignLanguage.languageImportanceLevel3"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="Lang4"
              v-model="staffRequestForeignLanguage.languageDescription4"
              label="languageName"
              :options="optionLang"
              :reduce="item => item.languageName"
            />
          </td>
          <td>
            <v-select
              id="Lang4Gerek"
              v-model="staffRequestForeignLanguage.languageImportanceLevel4"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="Lang5"
              v-model="staffRequestForeignLanguage.languageDescription5"
              label="languageName"
              :options="optionLang"
              :reduce="item => item.languageName"
            />
          </td>
          <td>
            <v-select
              id="Lang5Gerek"
              v-model="staffRequestForeignLanguage.languageImportanceLevel5"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
      </table>
      <!--Sertifika -->
      <table
        class="table table-bordered table-responsive-sm"
      >
        <tr>
          <td
            rowspan="6"
            class="col-3"
          >
            Sertifika
          </td>
        </tr>
        <tr>
          <td class="col-5">
            <v-select
              id="Ser1"
              v-model="staffRequestCertification.certificateDescription1"
              label="certificateName"
              :options="optionSert"
              :reduce="item => item.certificateName"
            />
          </td>
          <td class="col-4">
            <v-select
              id="Ser1Gerek"
              v-model="staffRequestCertification.certificateImportanceLevel1"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="Ser2"
              v-model="staffRequestCertification.certificateDescription2"
              label="certificateName"
              :options="optionSert"
              :reduce="item => item.certificateName"
            />
          </td>
          <td>
            <v-select
              id="Ser2Gerek"
              v-model="staffRequestCertification.certificateImportanceLevel2"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="Ser3"
              v-model="staffRequestCertification.certificateDescription3"
              label="certificateName"
              :options="optionSert"
              :reduce="item => item.certificateName"
            />
          </td>
          <td>
            <v-select
              id="Ser3Gerek"
              v-model="staffRequestCertification.certificateImportanceLevel3"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="Ser4"
              v-model="staffRequestCertification.certificateDescription4"
              label="certificateName"
              :options="optionSert"
              :reduce="item => item.certificateName"
            />
          </td>
          <td>
            <v-select
              id="ser4Gerek"
              v-model="staffRequestCertification.certificateImportanceLevel4"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="Ser5"
              v-model="staffRequestCertification.certificateDescription5"
              label="certificateName"
              :options="optionSert"
              :reduce="item => item.certificateName"
            />
          </td>
          <td>
            <v-select
              id="Ser5Gerek"
              v-model="staffRequestCertification.certificateImportanceLevel5"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
      </table>
      <!-- Sektör Tecribesi  -->
      <table
        class="table table-bordered table-responsive-sm"
      >
        <tr>
          <td
            rowspan="6"
            class="col-3"
          >
            Sektör Tecrübesi
          </td>
        </tr>
        <tr>
          <td class="col-2">
            <v-select
              id="SektorYil1"
              v-model="staffRequestSectorExperience.yearsOfExperience1"
              label="experienceYearTime"
              :options="optionYear"
            />
          </td>
          <td class="col-4">
            <v-select
              id="Sektor1"
              v-model="staffRequestSectorExperience.sectorDescription1"
              label="sectorName"
              :options="optionSector"
              :reduce="item => item.sectorName"
            />
          </td>
          <td class="col-3">
            <v-select
              id="Sektor1Gerek"
              v-model="staffRequestSectorExperience.sectorImportanceLevel1"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="SektorYil2"
              v-model="staffRequestSectorExperience.yearsOfExperience2"
              label="experienceYearTime"
              :options="optionYear"
            />
          </td>
          <td>
            <v-select
              id="Sektor2"
              v-model="staffRequestSectorExperience.sectorDescription2"
              label="sectorName"
              :options="optionSector"
              :reduce="item => item.sectorName"
            />
          </td>
          <td>
            <v-select
              id="Sektor2Gerek"
              v-model="staffRequestSectorExperience.sectorImportanceLevel2"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="SektorYil1"
              v-model="staffRequestSectorExperience.yearsOfExperience3"
              label="experienceYearTime"
              :options="optionYear"
            />
          </td>
          <td>
            <v-select
              id="Sektor3"
              v-model="staffRequestSectorExperience.sectorDescription3"
              label="sectorName"
              :options="optionSector"
              :reduce="item => item.sectorName"
            />
          </td>
          <td>
            <v-select
              id="Sektor3Gerek"
              v-model="staffRequestSectorExperience.sectorImportanceLevel3"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="SektorYil1"
              v-model="staffRequestSectorExperience.yearsOfExperience4"
              label="experienceYearTime"
              :options="optionYear"
            />
          </td>
          <td>
            <v-select
              id="Sektor4"
              v-model="staffRequestSectorExperience.sectorDescription4"
              label="sectorName"
              :options="optionSector"
              :reduce="item => item.sectorName"
            />
          </td>
          <td>
            <v-select
              id="Sektor4Gerek"
              v-model="staffRequestSectorExperience.sectorImportanceLevel4"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="SektorYil1"
              v-model="staffRequestSectorExperience.yearsOfExperience5"
              label="experienceYearTime"
              :options="optionYear"
            />
          </td>
          <td>
            <v-select
              id="Sektor5"
              v-model="staffRequestSectorExperience.sectorDescription5"
              label="sectorName"
              :options="optionSector"
              :reduce="item => item.sectorName"
            />
          </td>
          <td>
            <v-select
              id="Sektor5Gerek"
              v-model="staffRequestSectorExperience.sectorImportanceLevel5"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
      </table>
      <!-- Pozisyon Tecribesi  -->
      <table
        class="table table-bordered table-responsive-sm"
      >
        <tr>
          <td
            rowspan="6"
            class="col-3"
          >
            Pozisyon Tecrübesi
          </td>
        </tr>
        <tr>
          <td class="col-2">
            <v-select
              id="PozYil1"
              v-model="staffRequestPositionExperience.yearsOfExperience1"
              label="experienceYearTime"
              :options="optionYear"
            />
          </td>
          <td class="col-4">
            <v-select
              id="Poz1"
              v-model="staffRequestPositionExperience.positionDescription1"
              label="staffPositionName"
              :options="optionPozisyon"
              :reduce="item => item.staffPositionName"
            />
          </td>
          <td class="col-3">
            <v-select
              id="Poz1Gerek"
              v-model="staffRequestPositionExperience.positionImportanceLevel1"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="PozYil2"
              v-model="staffRequestPositionExperience.yearsOfExperience2"
              label="experienceYearTime"
              :options="optionYear"
            />
          </td>
          <td>
            <v-select
              id="Poz2"
              v-model="staffRequestPositionExperience.positionDescription2"
              label="staffPositionName"
              :options="optionPozisyon"
              :reduce="item => item.staffPositionName"
            />
          </td>
          <td>
            <v-select
              id="Poz2Gerek"
              v-model="staffRequestPositionExperience.positionImportanceLevel2"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="PozYil3"
              v-model="staffRequestPositionExperience.yearsOfExperience3"
              label="experienceYearTime"
              :options="optionYear"
            />
          </td>
          <td>
            <v-select
              id="Poz3"
              v-model="staffRequestPositionExperience.positionDescription3"
              label="staffPositionName"
              :options="optionPozisyon"
              :reduce="item => item.staffPositionName"
            />
          </td>
          <td>
            <v-select
              id="Poz3Gerek"
              v-model="staffRequestPositionExperience.positionImportanceLevel3"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="PozYil4"
              v-model="staffRequestPositionExperience.yearsOfExperience4"
              label="experienceYearTime"
              :options="optionYear"
            />
          </td>
          <td>
            <v-select
              id="Poz4"
              v-model="staffRequestPositionExperience.positionDescription4"
              label="staffPositionName"
              :options="optionPozisyon"
              :reduce="item => item.staffPositionName"
            />
          </td>
          <td>
            <v-select
              id="Poz4Gerek"
              v-model="staffRequestPositionExperience.positionImportanceLevel4"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-select
              id="PozYil4"
              v-model="staffRequestPositionExperience.yearsOfExperience5"
              label="experienceYearTime"
              :options="optionYear"
            />
          </td>
          <td>
            <v-select
              id="Poz5"
              v-model="staffRequestPositionExperience.positionDescription5"
              label="staffPositionName"
              :options="optionPozisyon"
              :reduce="item => item.staffPositionName"
            />
          </td>
          <td>
            <v-select
              id="Poz5Gerek"
              v-model="staffRequestPositionExperience.positionImportanceLevel5"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </td>
        </tr>
      </table>
    </b-form>
  </div>
</template>

<script>
import {
  BForm, BContainer, BRow, BCol, // BFormInput, // BTable, BTd, BTr, // BFormRadio, BFormInput, BFormCheckboxGroup, BInputGroup, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import * as apiGerek from '@/api/tanimlar/VKT/GerekSart'
import * as apiTahsil from '@/api/tanimlar/VKT/educationLevels'
import * as apiEML from '@/api/tanimlar/VocationalHighSchoolDepartment'
import * as apiOnlisans from '@/api/tanimlar/AssociateDegree'
import * as apiLisans from '@/api/tanimlar/BachelorDegree'
import * as apiMaster from '@/api/tanimlar/Postgraduate'
import * as apiYear from '@/api/tanimlar/VKT/Experience'
import * as apiComp from '@/api/tanimlar/ComputerKnowledge'
import * as apiLang from '@/api/tanimlar/Language'
import * as apiCert from '@/api/tanimlar/Certificate'
import * as apiSektor from '@/api/tanimlar/sector'
import * as apiPozisyon from '@/api/tanimlar/personelpozisyon'

import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    vSelect, BRow, BCol, BForm, BContainer, // BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    firmStaffRequestId: {
      type: Number,
      required: false,
      default: -1,
    },
    staffQualification: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      optionTahsil: [],
      optionsGerek: [],
      optionEML: [],
      optionMYO: [],
      optionLisans: [],
      optionMaster: [],
      optionYear: [],
      optionComp: [],
      optionLang: [],
      optionSert: [],
      optionPozisyon: [],
      optionSector: [],
      educationLevel: {},
      certificateName: '',
      //   educationLevelId: 1, educationLevelDescription: 'Okur Yazar Değil', educationLevelCoefficient: 0.1, rowState: 1,
      // },
    }
  },
  computed: {
    ...mapGetters({
      staffRequestCertification: 'staffRequest/GET_FIRMSTAFFREQUEST_CERTIFICATION',
      staffRequestComputer: 'staffRequest/GET_FIRMSTAFFREQUEST_COMPUTER',
      staffRequestForeignLanguage: 'staffRequest/GET_FIRMSTAFFREQUEST_FOREIGNLANGUAGE',
      staffRequestPositionExperience: 'staffRequest/GET_FIRMSTAFFREQUEST_POSITIONEXPERIENCE',
      staffRequestSectorExperience: 'staffRequest/GET_FIRMSTAFFREQUEST_SECTOREXPERIENCE',
      staffRequestQualification: 'staffRequest/GET_FIRMSTAFFREQUEST_QUALIFICATION',
    }),
  },
  watch: {
    educationLevel() {
      if (this.educationLevel !== null) {
        this.staffQualification.educationLevelId = this.educationLevel.educationLevelId
        this.staffQualification.educationLevel = this.educationLevel
      }
    },
  },
  beforeMount() {
    this.fetchStaffQualification(this.firmStaffRequestId).then(() => {
      this.staffQualification = this.staffRequestQualification
      if (this.staffQualification.educationLevel !== null) {
        this.educationLevel = this.staffQualification.educationLevel
      }
    })
  },
  mounted() {
    this.getGerek()
    this.getTahsil()
    this.getEML()
    this.getMYO()
    this.getLisans()
    this.getMaster()
    this.getYears()
    this.getComp()
    this.getLangs()
    this.getCert()
    this.getSector()
    this.getPozisyon()
    this.loadCertifications()
    this.loadComputers()
    this.loadForeignLanguages()
    this.loadPositionExperiences()
    this.loadSectorExperiences()
    if (this.staffQualification.educationLevel !== null) {
      this.educationLevel = this.staffQualification.educationLevel
    }
  },

  methods: {
    ...mapActions({
      fetchStaffRequestCertification: 'staffRequest/action_get_firmStaffRequestCertification',
      fetchStaffRequestComputer: 'staffRequest/action_get_firmStaffRequestComputer',
      fetchStaffRequestForeignLanguage: 'staffRequest/action_get_firmStaffRequestForeignLanguage',
      fetchStaffRequestPositionExperience: 'staffRequest/action_get_firmStaffRequestPositionExperience',
      fetchStaffRequestSectorExperience: 'staffRequest/action_get_firmStaffRequestSectorExperience',
      fetchStaffQualification: 'staffRequest/action_get_firmStaffRequestQualification',
    }),
    loadCertifications() {
      this.fetchStaffRequestCertification(this.firmStaffRequestId)
    },
    loadComputers() {
      this.fetchStaffRequestComputer(this.firmStaffRequestId)
    },
    loadForeignLanguages() {
      this.fetchStaffRequestForeignLanguage(this.firmStaffRequestId)
    },
    loadPositionExperiences() {
      this.fetchStaffRequestPositionExperience(this.firmStaffRequestId)
    },
    loadSectorExperiences() {
      this.fetchStaffRequestSectorExperience(this.firmStaffRequestId)
    },
    getGerek() {
      apiGerek.GetImportance().then(res => {
        this.optionsGerek = res.data.list
      })
    },
    getTahsil() {
      apiTahsil.GetEducations().then(res => {
        this.optionTahsil = res.data.list
      })
    },
    getEML() {
      apiEML.getVocationalHighSchoolDepartments().then(res => {
        this.optionEML = res.data.list
      })
    },
    getMYO() {
      apiOnlisans.getAssociateDegree().then(res => {
        this.optionMYO = res.data.list
      })
    },
    getLisans() {
      apiLisans.getBachelorDegrees().then(res => {
        this.optionLisans = res.data.list
      })
    },
    getMaster() {
      apiMaster.getPostgraduates().then(res => {
        this.optionMaster = res.data.list
      })
    },
    getYears() {
      apiYear.GetExpYears().then(res => {
        this.optionYear = res.data.list
      })
    },
    getComp() {
      apiComp.getComputerKnowledges().then(res => {
        this.optionComp = res.data.list
      })
    },
    getLangs() {
      apiLang.getLanguages().then(res => {
        this.optionLang = res.data.list
      })
    },
    getCert() {
      apiCert.getCertificates().then(res => {
        this.optionSert = res.data.list
      })
    },
    getSector() {
      apiSektor.getSectorsAll().then(res => {
        this.optionSector = res.data.list
      })
    },
    getPozisyon() {
      apiPozisyon.GetPersonelPozisyonList().then(res => {
        this.optionPozisyon = res.data.list
      })
    },
  },
}
</script>

<style lang='scss'>
   @import '@core/scss/vue/libs/vue-select.scss';
</style>
