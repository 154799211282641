<template>
  <div>
    <b-form>
      <b-container
        class="bv-example-row"
        name="p1"
      >
        <!-- Cinsiyet Satırı -->
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-row>
              <b-col
                cols="12"
              >
                <b-row>
                  <b-col cols="4">
                    <label>Cinsiyet</label>
                  </b-col>
                  <b-col cols="4">
                    <b-form-radio
                      v-model="firmStaffRequest.gender"
                      plain
                      name="gender"
                      :value="1"
                    >
                      Erkek
                    </b-form-radio>
                  </b-col>
                  <b-col cols="4">
                    <b-form-radio
                      v-model="firmStaffRequest.gender"
                      plain
                      name="gender"
                      :value="2"
                    >
                      Kadın
                    </b-form-radio>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row class="mb-3">
              <b-col
                cols="12"
                md="4"
                class="pl-4"
              >
                <label>Gereklilik Seviyesi</label>
              </b-col>
              <b-col>
                <v-select
                  id="genderImportanceLevel"
                  v-model="firmStaffRequest.genderImportanceLevelId"
                  label="importanceLevelDescription"
                  :options="optionsGerek"
                  :reduce="item => item.importanceLevelId"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- Medeni Hal Satırı -->
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-row>
              <b-col
                cols="12"
              >
                <b-row>
                  <b-col cols="4">
                    <label>Medeni Hali</label>
                  </b-col>
                  <b-col cols="4">
                    <b-form-radio
                      v-model="firmStaffRequest.maritalStatus"
                      plain
                      name="maritalStatus"
                      value="1"
                    >
                      Evli
                    </b-form-radio>
                  </b-col>
                  <b-col cols="4">
                    <b-form-radio
                      v-model="firmStaffRequest.maritalStatus"
                      plain
                      name="maritalStatus"
                      value="2"
                    >
                      Bekar
                    </b-form-radio>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row class="mb-3">
              <b-col
                cols="12"
                md="4"
                class="pl-4"
              >
                <label>Gereklilik Seviyesi</label>
              </b-col>
              <b-col>
                <v-select
                  id="maritalImportanceLevel"
                  v-model="firmStaffRequest.maritalImportanceLevelId"
                  label="importanceLevelDescription"
                  :options="optionsGerek"
                  :reduce="item => item.importanceLevelId"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- Yaş Satırı -->
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-row>
              <b-col
                cols="12"
              >
                <b-row>
                  <b-col cols="4">
                    <label>Yaş</label>
                  </b-col>
                  <b-col cols="4">
                    <b-form-input
                      id="ageMin"
                      v-model="firmStaffRequest.ageMin"
                      value="0"
                      placeholder="Minimum Yaş"
                      type="number"
                    />
                  </b-col>
                  <b-col cols="4">
                    <b-form-input
                      id="ageMax"
                      v-model="firmStaffRequest.ageMax"
                      placeholder="Maximum Yaş"
                      value="0"
                      type="number"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row class="mb-3">
              <b-col
                cols="12"
                md="4"
                class="pl-4"
              >
                <label>Gereklilik Seviyesi</label>
              </b-col>
              <b-col>
                <v-select
                  id="ageImportanceLevel"
                  v-model="firmStaffRequest.ageImportanceLevelId"
                  label="importanceLevelDescription"
                  :options="optionsGerek"
                  :reduce="item => item.importanceLevelId"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- Boy Satırı -->
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-row>
              <b-col
                cols="12"
              >
                <b-row>
                  <b-col cols="4">
                    <label>Boy</label>
                  </b-col>
                  <b-col cols="4">
                    <b-form-input
                      id="heightMin"
                      v-model="firmStaffRequest.heightMin"
                      value="0"
                      placeholder="Minimum Boy"
                      type="number"
                    />
                  </b-col>
                  <b-col cols="4">
                    <b-form-input
                      id="heightMax"
                      v-model="firmStaffRequest.heightMax"
                      placeholder="Maximum Boy"
                      value="0"
                      type="number"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row class="mb-3">
              <b-col
                cols="12"
                md="4"
                class="pl-4"
              >
                <label>Gereklilik Seviyesi</label>
              </b-col>
              <b-col>
                <v-select
                  id="heightImportanceLevel"
                  v-model="firmStaffRequest.heightImportanceLevelId"
                  label="importanceLevelDescription"
                  :options="optionsGerek"
                  :reduce="item => item.importanceLevelId"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- Kilo Satırı -->
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-row>
              <b-col
                cols="12"
              >
                <b-row>
                  <b-col cols="4">
                    <label>Kilo</label>
                  </b-col>
                  <b-col cols="4">
                    <b-form-input
                      id="weightMin"
                      v-model="firmStaffRequest.weightMin"
                      value="0"
                      placeholder="Minimum Kilo"
                      type="number"
                    />
                  </b-col>
                  <b-col cols="4">
                    <b-form-input
                      id="heightMax"
                      v-model="firmStaffRequest.weightMax"
                      placeholder="Maximum Kilo"
                      value="0"
                      type="number"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row class="mb-3">
              <b-col
                cols="12"
                md="4"
                class="pl-4"
              >
                <label>Gereklilik Seviyesi</label>
              </b-col>
              <b-col>
                <v-select
                  id="weightImportanceLevel"
                  v-model="firmStaffRequest.weightImportanceLevelId"
                  label="importanceLevelDescription"
                  :options="optionsGerek"
                  :reduce="item => item.importanceLevelId"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- Askerlik Durumu  -->
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <b-row>
              <b-col
                cols="12"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <label>Askerlik Durumu</label>
                  </b-col>
                  <b-col
                    cols="3"
                    md="2"
                  >
                    <b-form-radio
                      v-model="firmStaffRequest.militaryStatus"
                      plain
                      name="militaryStatus"
                      value="1"
                    >
                      Yapıldı
                    </b-form-radio>
                  </b-col>
                  <b-col
                    cols="3"
                    md="2"
                  >
                    <b-form-radio
                      v-model="firmStaffRequest.militaryStatus"
                      plain
                      name="militaryStatus"
                      value="2"
                    >
                      Yapılmadı
                    </b-form-radio>
                  </b-col>
                  <b-col
                    cols="3"
                    md="2"
                  >
                    <b-form-radio
                      v-model="firmStaffRequest.militaryStatus"
                      plain
                      name="militaryStatus"
                      value="3"
                    >
                      Muaf
                    </b-form-radio>
                  </b-col>
                  <b-col
                    cols="3"
                    md="2"
                  >
                    <b-form-radio
                      v-model="firmStaffRequest.militaryStatus"
                      plain
                      name="militaryStatus"
                      value="4"
                    >
                      Tecilli
                    </b-form-radio>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row class="mb-3">
              <b-col
                cols="12"
                md="4"
                class="pl-4"
              >
                <label>Gereklilik Seviyesi</label>
              </b-col>
              <b-col>
                <v-select
                  id="militaryStatusImportanceLevel"
                  v-model="firmStaffRequest.militaryStatusImportanceLevelId"
                  label="importanceLevelDescription"
                  :options="optionsGerek"
                  :reduce="item => item.importanceLevelId"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- İlçe Satırı -->
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <b-row>
              <b-col
                cols="12"
              >
                <b-row>
                  <b-col cols="2">
                    <label>İlçeler</label>
                  </b-col>
                  <b-col cols="10">
                    <v-select
                      v-model="firmStaffRequest.towns"
                      label="townName"
                      :options="optionTowns"
                      :reduce="item => item.townName"
                      multiple
                    >
                      <span slot="no-options">Yüklü veri bulunamadı</span>
                    </v-select>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row class="mb-3">
              <b-col
                cols="12"
                md="4"
                class="pl-4"
              >
                <label>Gereklilik Seviyesi</label>
              </b-col>
              <b-col>
                <v-select
                  id="weightImportanceLevel"
                  v-model="firmStaffRequest.townsImportanceLevelId"
                  label="importanceLevelDescription"
                  :options="optionsGerek"
                  :reduce="item => item.importanceLevelId"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- Engellilik Sigara  -->
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Engellik Durumu Olmamalı</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="isDisabled"
              v-model="firmStaffRequest.isNotDisabledImportanceLevelId"
              label="importanceLevelDescription"
              :options="optionsGerek"
              :reduce="item => item.importanceLevelId"
            />
          </b-col>
          <b-col
            cols="6"
            md="2"
          >
            <label>Sigara Kullanmamalı </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="isSmoker"
              v-model="firmStaffRequest.isNotSmokerImportanceLevelId"
              label="importanceLevelDescription"
              :options="optionsGerek"
              :reduce="item => item.importanceLevelId"
            />
          </b-col>
        </b-row>
        <!-- Seyehat Vardiya  -->
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Seyahat Engeli Olmamalı</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="canTravel"
              v-model="firmStaffRequest.canTravelImportanceLevelId"
              label="importanceLevelDescription"
              :options="optionsGerek"
              :reduce="item => item.importanceLevelId"
            />
          </b-col>
          <b-col
            cols="6"
            md="2"
          >
            <label>Vardiyalı Çalışabilmeli </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="workShifts"
              v-model="firmStaffRequest.hasWorkShiftsImportanceLevelId"
              label="importanceLevelDescription"
              :options="optionsGerek"
              :reduce="item => item.importanceLevelId"
            />
          </b-col>
        </b-row>
        <!-- Referans Sabıka  -->
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Referans Gösterebilmeli</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="hasReferences"
              v-model="firmStaffRequest.hasReferencesImportanceLevelId"
              label="importanceLevelDescription"
              :options="optionsGerek"
              :reduce="item => item.importanceLevelId"
            />
          </b-col>
          <b-col
            cols="6"
            md="2"
          >
            <label>Sabıka Kaydı Olmamalı </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="criminalRecord"
              v-model="firmStaffRequest.hasNotCriminalRecordImportanceLevelId"
              label="importanceLevelDescription"
              :options="optionsGerek"
              :reduce="item => item.importanceLevelId"
            />
          </b-col>
        </b-row>
        <!-- Aktif Araç Ehliyet  -->
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Aktif Araç Kullanabilmeli</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="canDrive"
              v-model="firmStaffRequest.canDriveImportanceLevelId"
              label="importanceLevelDescription"
              :options="optionsGerek"
              :reduce="item => item.importanceLevelId"
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Ehliyet Sınıfı </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="driverLicenceType"
              v-model="firmStaffRequest.driverLicenseType"
              label="driverLicenceTypeName"
              :options="optionEhliyet"
            />
          </b-col>
          <b-col
            cols="6"
            md="2"
          >
            <label>Gereklilik Seviyesi</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="driverLicenceImportanceLevel"
              v-model="firmStaffRequest.driverLicenseImportanceLevel"
              label="importanceLevelDescription"
              :options="optionsGerek"
            />
          </b-col>
        </b-row>
        <!-- Engel Hastalıklar -->
        <b-row class="mb-1">
          <b-col
            cols="12"
            md="12"
          >
            <b-row>
              <b-col cols="2">
                <label>Engel Hastalıklar</label>
              </b-col>
              <b-col cols="10">
                <v-select
                  v-model="firmStaffRequest.obstructiveDiseases"
                  label="obstacleDiseaseName"
                  :options="optionEngel"
                  :reduce="item=> item.obstacleDiseaseName"
                  multiple
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>
<script>
import {
  BForm, BContainer, BRow, BCol, BFormRadio, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import * as apiGerek from '@/api/tanimlar/VKT/GerekSart'
import * as apiIlce from '@/api/tanimlar/town'
import * as apiEhliyet from '@/api/tanimlar/DriverLicenceType'
import * as apiEngel from '@/api/tanimlar/ObstacleDisease'

import * as apiAddress from '@/api/islemler/firmAdres'

export default {
  components: {
    vSelect, BForm, BContainer, BRow, BCol, BFormRadio, BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    firmStaffRequest: {
      type: Object,
      required: false,
      default: null,
    },
    firmId: {
      type: Number,
      required: true,
      default: -1,
    },
  },
  data() {
    return {
      optionsGerek: [],
      optionTowns: [],
      optionEhliyet: [],
      optionEngel: [],
    }
  },
  watch: {
    firmId() {
      this.getTowns()
    },
    // townsImportanceLevel() {
    //  this.firmStaffRequest.townsImportanceLevelId = this.firmStaffRequest.townsImportanceLevel.importanceLevelId
    // },
    // driverLicenseImportanceLevel() {
    //  this.firmStaffRequest.driverLicenseImportanceLevelId = this.firmStaffRequest.driverLicenseImportanceLevel.importanceLevelId
    // },
    // driverLicenseType() {
    //  this.firmStaffRequest.driverLicenseTypeId = this.firmStaffRequest.driverLicenseType.driverLicenseTypeId
    // },
  },
  created() {
    this.getGerek()
    this.getEhliyet()
    this.getEngel()
  },
  mounted() {
    this.getTowns()
  },
  methods: {
    getGerek() {
      apiGerek.GetImportance().then(res => {
        this.optionsGerek = res.data.list
      })
    },
    getTowns() {
      if (this.firmId > 0) {
        apiAddress.GetFirmAddressByFirmId(this.firmId).then(addrResp => {
          apiIlce.GetTownsByCityId(addrResp.data.entity.city.cityId).then(townResp => {
            this.optionTowns = townResp.data.list
          })
        })
      }
    },
    getEhliyet() {
      apiEhliyet.getDriverLicenceTypes().then(res => {
        this.optionEhliyet = res.data.list
      })
    },
    getEngel() {
      apiEngel.getObstacleDiseases().then(res => {
        this.optionEngel = res.data.list
      })
    },
  },
}
</script>

<style lang='scss'>
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
