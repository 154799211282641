<template>
  <div>
    <b-row class="mb-1">
      <b-col
        cols="12"
        md="2"
      >
        <b>Hafta içi gündüz mesai saatleri</b>
      </b-col>
      <b-col
        cols="6"
        md="2"
      >
        <b-form-input
          v-model="firmStaffRequest.firmWeekdayMorningWorkingHoursStart"
          type="time"
          name="tm111"
          class="small"
        />
      </b-col>
      <b-col
        cols="6"
        md="2"
      >
        <b-form-input
          v-model="firmStaffRequest.firmWeekdayMorningWorkingHoursEnd"
          type="time"
          name="tm112"
          class="small"
        />
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b> Öğle arası</b>
      </b-col>
      <b-col
        cols="6"
        md="2"
      >
        <b-form-input
          v-model="firmStaffRequest.firmWeekdayLunchBreakStart"
          type="time"
          name="tm121"
          class="small"
        />
      </b-col>
      <b-col
        cols="6"
        md="2"
      >
        <b-form-input
          v-model="firmStaffRequest.firmWeekdayLunchBreakEnd"
          type="time"
          name="tm122"
          class="small"
        />
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col
        cols="12"
        md="2"
      >
        <b>Hafta sonu gündüz mesai saatleri</b>
      </b-col>
      <b-col
        cols="6"
        md="2"
      >
        <b-form-input
          v-model="firmStaffRequest.firmWeekendMorningWorkingHoursStart"
          type="time"
          name="tm211"
          class="small"
        />
      </b-col>
      <b-col
        cols="6"
        md="2"
      >
        <b-form-input
          v-model="firmStaffRequest.firmWeekendMorningWorkingHoursEnd"
          type="time"
          name="tm212"
          class="small"
        />
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b>Öğle arası</b>
      </b-col>
      <b-col
        cols="6"
        md="2"
      >
        <b-form-input
          v-model="firmStaffRequest.firmWeekendLunchBreakStart"
          type="time"
          name="tm221"
          class="small"
        />
      </b-col>
      <b-col
        cols="6"
        md="2"
      >
        <b-form-input
          v-model="firmStaffRequest.firmWeekendLunchBreakEnd"
          type="time"
          name="tm222"
          class="small"
        />
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col
        cols="12"
        md="2"
      >
        <b>Hafta içi vardiya saatleri</b>
      </b-col>
      <b-col
        cols="6"
        md="2"
      >
        <b-form-input
          v-model="firmStaffRequest.firmWeekdayOvertimeStart"
          type="time"
          name="tm311"
          class="small"
        />
      </b-col>
      <b-col
        cols="6"
        md="2"
      >
        <b-form-input
          v-model="firmStaffRequest.firmWeekdayOvertimeEnd"
          type="time"
          name="tm312"
          class="small"
        />
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b for="tm32">Dinlenme arası</b>
      </b-col>
      <b-col
        cols="6"
        md="2"
      >
        <b-form-input
          v-model="firmStaffRequest.firmWeekdayOvertimeBreakStart"
          type="time"
          name="tm321"
          class="small"
        />
      </b-col>
      <b-col
        cols="6"
        md="2"
      >
        <b-form-input
          v-model="firmStaffRequest.firmWeekdayOvertimeBreakEnd"
          type="time"
          name="tm322"
          class="small"
        />
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col
        cols="12"
        md="2"
      >
        <b>Hafta sonu vardiya saatleri</b>
      </b-col>
      <b-col
        cols="6"
        md="2"
      >
        <b-form-input
          v-model="firmStaffRequest.firmWeekendOvertimeStart"
          type="time"
          name="tm411"
          class="small"
        />
      </b-col>
      <b-col
        cols="6"
        md="2"
      >
        <b-form-input
          v-model="firmStaffRequest.firmWeekendOvertimeEnd"
          type="time"
          name="tm412"
          class="small"
        />
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b>Dinlenme arası</b>
      </b-col>
      <b-col
        cols="6"
        md="2"
      >
        <b-form-input
          v-model="firmStaffRequest.firmWeekendOvertimeBreakStart"
          type="time"
          name="tm421"
          class="small"
        />
      </b-col>
      <b-col
        cols="6"
        md="2"
      >
        <b-form-input
          v-model="firmStaffRequest.firmWeekendOvertimeBreakEnd"
          type="time"
          name="tm422"
          class="small"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="10"
      >
        <b-row class="mb-2">
          <div>
            <b-form-group>
              <p class="fs-3 font-weight-bolder">
                Ulaşım imkanları.
              </p>
              <b-form-radio-group
                v-model="selecteds01"
                :options="optionss01"
              />
            </b-form-group>
          </div>
        </b-row>
        <b-row class="mb-2">
          <div>
            <b-form-group>
              <p class="fs-3 font-weight-bolder">
                Yemek imkanları.
              </p>
              <b-form-radio-group
                v-model="selecteds02"
                :options="optionss02"
              />
            </b-form-group>
          </div>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="2"
        class="row justify-content-center align-items-center"
      >
        <div class="text-center">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            squared
            variant="info"
            @click="onGetWorkHoursClick()"
          >
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
            />
            <span>Firma Kaydından Getir</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCol, BRow, BFormRadioGroup, BFormGroup, BButton, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import * as apiFirmInfo from '@/api/islemler/firminventory'
import * as apiFBenefit from '@/api/islemler/FirmBenefits'

export default {
  components: {
    BFormInput,
    BCol,
    BRow,
    BFormGroup,
    BFormRadioGroup,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    firmStaffRequest: {
      type: Object,
      required: false,
      default: null,
    },
    firmId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      selecteds01: '',
      optionss01: [
        { html: '<p style="font-size:12px;">Servis var.</p>', value: 's011', disabled: false },
        { html: '<p style="font-size:12px;">Yol yardımı var.</p>', value: 's012', disabled: false },
        { html: '<p style="font-size:12px;">Servis yada yol yardımı var.</p>', value: 's013', disabled: false },
        { html: '<p style="font-size:12px;">Anlaşmaya bağlı.</p>', value: 's014', disabled: false },
        { html: '<p style="font-size:12px;">Yok.</p>', value: 's015', disabled: false },
      ],
      selecteds02: '',
      optionss02: [
        { html: '<p style="font-size:12px;">Şirkette karşılanır.</p>', value: 's021', disabled: false },
        { html: '<p style="font-size:12px;">Ticket verilir.</p>', value: 's022', disabled: false },
        { html: '<p style="font-size:12px;">Şirket yada Ticket verilir.</p>', value: 's023', disabled: false },
        { html: '<p style="font-size:12px;">Ücret olarak ödenir.</p>', value: 's024', disabled: false },
        { html: '<p style="font-size:12px;">Yok.</p>', value: 's025', disabled: false },
      ],
      firm: {},
      fBenefit: {},
    }
  },
  watch: {
    selecteds01() {
      this.firmStaffRequest.hasTransportationVehicle = this.selecteds01 === 's011'
      this.firmStaffRequest.hasTransportationSurcharge = this.selecteds01 === 's012'
      this.firmStaffRequest.hasTransportationVehicleOrSurcharge = this.selecteds01 === 's013'
      this.firmStaffRequest.hasTransportationDependsContract = this.selecteds01 === 's014'
      this.firmStaffRequest.hasTransportationUnavailable = this.selecteds01 === 's015'
    },
    selecteds02() {
      this.firmStaffRequest.hasMealProvidedInCompany = this.selecteds02 === 's021'
      this.firmStaffRequest.hasMealByTicket = this.selecteds02 === 's022'
      this.firmStaffRequest.hasMealInCompanyOrTicket = this.selecteds02 === 's023'
      this.firmStaffRequest.hasMealSurcharge = this.selecteds02 === 's024'
      this.firmStaffRequest.hasMealUnavailable = this.selecteds02 === 's025'
    },
  },
  beforeUpdate() {
    if (this.firmStaffRequest.hasTransportationVehicle) this.selecteds01 = 's011'
    if (this.firmStaffRequest.hasTransportationSurcharge) this.selecteds01 = 's012'
    if (this.firmStaffRequest.hasTransportationVehicleOrSurcharge) this.selecteds01 = 's013'
    if (this.firmStaffRequest.hasTransportationDependsContract) this.selecteds01 = 's014'
    if (this.firmStaffRequest.hasTransportationUnavailable) this.selecteds01 = 's015'

    if (this.firmStaffRequest.hasMealProvidedInCompany) this.selecteds02 = 's021'
    if (this.firmStaffRequest.hasMealByTicket) this.selecteds02 = 's022'
    if (this.firmStaffRequest.hasMealInCompanyOrTicket) this.selecteds02 = 's023'
    if (this.firmStaffRequest.hasMealSurcharge) this.selecteds02 = 's024'
    if (this.firmStaffRequest.hasMealUnavailable) this.selecteds02 = 's025'
  },
  methods: {
    onGetWorkHoursClick() {
      if (this.firmId > 0) {
        apiFirmInfo.GetFirm(this.firmId).then(frm => {
          this.firm = frm.data.entity
          apiFBenefit.GetBenefitByFirmId(this.firmId).then(bnf => {
            this.fBenefit = bnf.data.entity
            this.firmStaffRequest.firmWeekdayMorningWorkingHoursStart = this.firm.firmWeekdayMorningWorkingHoursStart
            this.firmStaffRequest.firmWeekdayMorningWorkingHoursEnd = this.firm.firmWeekdayMorningWorkingHoursEnd
            this.firmStaffRequest.firmWeekdayLunchBreakStart = this.firm.firmWeekdayLunchBreakStart
            this.firmStaffRequest.firmWeekdayLunchBreakEnd = this.firm.firmWeekdayLunchBreakEnd
            this.firmStaffRequest.firmWeekdayOvertimeStart = this.firm.firmWeekdayOvertimeStart
            this.firmStaffRequest.firmWeekdayOvertimeEnd = this.firm.firmWeekdayOvertimeEnd
            this.firmStaffRequest.firmWeekdayOvertimeBreakStart = this.firm.firmWeekdayOvertimeBreakStart
            this.firmStaffRequest.firmWeekdayOvertimeBreakEnd = this.firm.firmWeekDayOvertimeBreakEnd
            this.firmStaffRequest.firmWeekendMorningWorkingHoursStart = this.firm.firmWeekendMorningWorkingHoursStart
            this.firmStaffRequest.firmWeekendMorningWorkingHoursEnd = this.firm.firmWeekendMorningWorkingHoursEnd
            this.firmStaffRequest.firmWeekendLunchBreakStart = this.firm.firmWeekendLunchBreakStart
            this.firmStaffRequest.firmWeekendLunchBreakEnd = this.firm.firmWeekendLunchBreakEnd
            this.firmStaffRequest.firmWeekendOvertimeStart = this.firm.firmWeekendOvertimeStart
            this.firmStaffRequest.firmWeekendOvertimeEnd = this.firm.firmWeekendOvertimeEnd
            this.firmStaffRequest.firmWeekendOvertimeBreakStart = this.firm.firmWeekendOvertimeBreakStart
            this.firmStaffRequest.firmWeekendOvertimeBreakEnd = this.firm.firmWeekendOvertimeBreakEnd
            if (this.fBenefit.transportationVehicle) this.selecteds01 = 's011'
            if (this.fBenefit.transportationSurcharge) this.selecteds01 = 's012'
            if (this.fBenefit.transportationVehicleOrSurcharge) this.selecteds01 = 's013'
            if (this.fBenefit.transportationDependsOnContract) this.selecteds01 = 's014'
            if (this.fBenefit.transportationUnavailable) this.selecteds01 = 's015'
            if (this.fBenefit.mealProvidedInCompany) this.selecteds02 = 's021'
            if (this.fBenefit.mealByTicket) this.selecteds02 = 's022'
            if (this.fBenefit.mealInCompanyOrTicket) this.selecteds02 = 's023'
            if (this.fBenefit.mealSurcharge) this.selecteds02 = 's024'
            if (this.fBenefit.mealUnavailable) this.selecteds02 = 's025'
          })
        })
      }
    },
  },
}
</script>
