<template>
  <div>
    <b-form>
      <b-container>
        <!-- Sayısal Analiitik  -->
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Sayısal Yetenek</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="numericalAbilityCompetencyLevel"
              v-model="staffQualification.numericalAbilityCompetencyLevel"
              label="technicalBasicCompetencyDescription"
              :options="optionsTemelYetenek"
            />
          </b-col>
          <b-col
            cols="6"
            md="2"
          >
            <label>Analitik Düşünce</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="AnalyticalImportanceLevel"
              v-model="staffQualification.analyticalAbilityCompetencyLevel"
              label="technicalBasicCompetencyDescription"
              :options="optionsTemelYetenek"
            />
          </b-col>
        </b-row>
        <!-- Dikkat Öğrenebilme  -->
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Dikkat Yeteneği</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="attentionAbilityCompetencyLevel"
              v-model="staffQualification.attentionAbilityCompetencyLevel"
              label="technicalBasicCompetencyDescription"
              :options="optionsTemelYetenek"
            />
          </b-col>
          <b-col
            cols="6"
            md="2"
          >
            <label>Öğrenebilme</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="learnabilityCompetencyLevel"
              v-model="staffQualification.learnabilityCompetencyLevel"
              label="technicalBasicCompetencyDescription"
              :options="optionsTemelYetenek"
            />
          </b-col>
        </b-row>
        <!-- Uygulama Açıklık  -->
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Öğrendiğini Uygulayabilme</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="applyingAbilityCompetencyLevel"
              v-model="staffQualification.applyingAbilityCompetencyLevel"
              label="technicalBasicCompetencyDescription"
              :options="optionsTemelYetenek"
            />
          </b-col>
          <b-col
            cols="6"
            md="2"
          >
            <label>Yenilik ve Değişime Açıklık</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="innovationAbilityCompetencyLevel"
              v-model="staffQualification.innovationAbilityCompetencyLevel"
              label="technicalBasicCompetencyDescription"
              :options="optionsTemelYetenek"
            />
          </b-col>
        </b-row>
        <!-- İletişim Karar  -->
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>İletişim ve Kendini Geliştirme</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="communicationAbilityCompetencyLevel"
              v-model="staffQualification.communicationAbilityCompetencyLevel"
              label="technicalBasicCompetencyDescription"
              :options="optionsTemelYetenek"
            />
          </b-col>
          <b-col
            cols="6"
            md="2"
          >
            <label>Problem Çözme ve Karar Verme</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="solutionAbilityCompetencyLevel"
              v-model="staffQualification.solutionAbilityCompetencyLevel"
              label="technicalBasicCompetencyDescription"
              :options="optionsTemelYetenek"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>

<script>
import {
  BForm, BContainer, BRow, BCol, // BFormRadio, BFormInput, BFormCheckboxGroup, BInputGroup, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import * as apiTemelYetenek from '@/api/tanimlar/VKT/temelyetenek'

export default {
  components: {
    vSelect, BRow, BCol, BForm, BContainer,
  },
  directives: {
    Ripple,
  },
  props: {
    staffQualification: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      optionsTemelYetenek: [],
    }
  },
  watch: {
    numericalAbilityCompetencyLevel() {
      this.staffQualification.numericalAbilityCompetencyLevelId = this.staffQualification.numericalAbilityCompetencyLevel.technicalBasicCompetencyId
    },
    analyticalAbilityCompetencyLevel() {
      this.staffQualification.analyticalAbilityCompetencyLevelId = this.staffQualification.analyticalAbilityCompetencyLevel.technicalBasicCompetencyId
    },
    attentionAbilityCompetencyLevel() {
      this.staffQualification.attentionAbilityCompetencyLevelId = this.staffQualification.attentionAbilityCompetencyLevel.technicalBasicCompetencyId
    },
    learnabilityCompetencyLevel() {
      this.staffQualification.learnabilityCompetencyLevelId = this.staffQualification.learnabilityCompetencyLevel.technicalBasicCompetencyId
    },
    applyingAbilityCompetencyLevel() {
      this.staffQualification.applyingAbilityCompetencyLevelId = this.staffQualification.applyingAbilityCompetencyLevel.technicalBasicCompetencyId
    },
    innovationAbilityCompetencyLevel() {
      this.staffQualification.innovationAbilityCompetencyLevelId = this.staffQualification.innovationAbilityCompetencyLevel.technicalBasicCompetencyId
    },
    solutionAbilityCompetencyLevel() {
      this.staffQualification.solutionAbilityCompetencyLevelId = this.staffQualification.solutionAbilityCompetencyLevel.technicalBasicCompetencyId
    },
  },
  mounted() {
    this.apiTemelYetenek()
  },
  methods: {
    apiTemelYetenek() {
      apiTemelYetenek.GetTechnicalBasicCompetencies().then(res => {
        this.optionsTemelYetenek = res.data.list
      })
    },
  },
}
</script>
