import request from '@/utils/request'

export function GetStaffClasses() {
  return request({
    url: '/api/StaffClass/GetStaffClasses',
    method: 'get',
  })
}

export function GetStaffClassById(data) {
  return request({
    url: `/api/StaffClass/GetStaffClassById/${data}`,
    method: 'get',
  })
}
