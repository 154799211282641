<template>
  <div>
    <!-- <div>
      <label class="h5">Talep Oluşturulan Firma: {{ firmId }} : {{ firmName }}  - Talep Id: {{ firmStaffRequestId }} </label>
      <br>
      <label class="h4"> Talep durumu {{ durum(firmStaffRequest.requestStatus) }} </label>
    </div> -->
    <tabBaslik
      :firm-staff-request="firmStaffRequest"
      :staff-qualification="staffQualification"
      :firm-id="firmId"
    />
    <br>
    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="WatchIcon" />
          <span> Mesai ve Sosyal Haklar </span>
        </template>
        <tab-mesai
          :firm-staff-request="firmStaffRequest"
          :firm-id="firmId"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="SlackIcon" />
          <span> Ücret ve Sosyal Haklar </span>
        </template>
        <tab-ucret :firm-staff-request="firmStaffRequest" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FramerIcon" />
          <span> Riskler </span>
        </template>
        <tab-risk :firm-staff-request="firmStaffRequest" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserCheckIcon" />
          <span> Kişisel Özellikler </span>
        </template>
        <tab-kisisel
          :firm-staff-request="firmStaffRequest"
          :firm-id="firmId"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="SlidersIcon" />
          <span> Mesleki Yeterlilik </span>
        </template>
        <tab-mesleki
          :firm-staff-request-id="firmStaffRequestId"
          :staff-qualification="staffQualification"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ActivityIcon" />
          <span> Temel Yetenek </span>
        </template>
        <tab-temel :staff-qualification="staffQualification" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="TargetIcon" />
          <span> Teknik Yeterlilik </span>
        </template>
        <tab-teknik :staff-qualification="staffQualification" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
import tabBaslik from '@/views/islemler/talep/talepbaslik.vue'
import tabMesai from '@/views/islemler/talep/talepmesai.vue'
import tabUcret from '@/views/islemler/talep/talepucret.vue'
import tabRisk from '@/views/islemler/talep/taleprisk.vue'
import tabKisisel from '@/views/islemler/talep/talepkisisel.vue'
import tabMesleki from '@/views/islemler/talep/talepmesleki.vue'
import tabTemel from '@/views/islemler/talep/taleptemel.vue'
import tabTeknik from '@/views/islemler/talep/talepteknik.vue'

export default {
  components: {
    BTabs,
    BTab,
    tabBaslik,
    tabMesai,
    tabUcret,
    tabRisk,
    tabKisisel,
    tabMesleki,
    tabTemel,
    tabTeknik,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  data() {
    return {
      logoB64: '',
      firmId: 0,
      firmName: '',
      firmInfo: this.$route.params.firmInfo,
      firmStaffRequestId: this.$route.params.firmStaffRequestId,
    }
  },
  computed: {
    ...mapGetters({
      firmStaffRequest: 'staffRequest/GET_FIRMSTAFFREQUEST',
      staffQualification: 'staffRequest/GET_FIRMSTAFFREQUEST_QUALIFICATION',
    }),
  },
  beforeMount() {
    this.firmStaffRequestId = this.$route.params.firmStaffRequestId
    this.getFirmRequest()
    this.getStaffQualification()
  },
  mounted() {
    if (this.firmInfo == null) {
      this.$router.push({ name: 'home' })
    } else {
      this.firmId = this.firmInfo.firmInfoId
      this.firmStaffRequest.firmInfoId = this.firmInfo.firmInfoId
      this.firmName = this.firmInfo.firmTitle
    }
  },
  methods: {
    ...mapMutations({
      setFirmStaffRequest: 'staffRequest/SET_FIRMSTAFFREQUEST',
      setFirmInfo: 'firmInfo/SET_FIRMINFO',
    }),
    ...mapActions({
      fetchFirmStaffRequest: 'staffRequest/action_get_firmStaffRequest',
      fetchStaffQualification: 'staffRequest/action_get_firmStaffRequestQualification',
      fetchFirmInfo: 'firmInfo/action_get_firminfo',
    }),
    getFirmRequest() {
      this.fetchFirmStaffRequest(this.firmStaffRequestId)
    },
    getStaffQualification() {
      this.fetchStaffQualification(this.firmStaffRequestId)
    },
    durum(secim) {
      switch (secim) {
        case 0:
          return 'Aktif'
        case 1:
          return 'Eşleştirilmiş'
        case 2:
          return 'Kapatılmış'
        case -1:
          return 'Pasif'
        default:
          return 'Aktif'
      }
    },
  },
}
</script>
