<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-row class="mb-1">
          <b-col
            cols="12"
            md="4"
            class="pl-4"
          >
            <label>Mali Risk</label>
          </b-col>
          <b-col>
            <v-select
              id="financialRisk"
              v-model="firmStaffRequest.financialRiskLevel"
              label="riskLevelDescription"
              :options="optionsRisk"
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="12"
            md="4"
            class="pl-4"
          >
            <label>Hukuki Risk</label>
          </b-col>
          <b-col>
            <v-select
              id="legalRisk"
              v-model="firmStaffRequest.legalRiskLevel"
              label="riskLevelDescription"
              :options="optionsRisk"
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="12"
            md="4"
            class="pl-4"
          >
            <label>Sağlık Riski</label>
          </b-col>
          <b-col>
            <v-select
              id="medicalRisk"
              v-model="firmStaffRequest.medicalRiskLevel"
              label="riskLevelDescription"
              :options="optionsRisk"
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="12"
            md="4"
            class="pl-4"
          >
            <label>Hayati Risk</label>
          </b-col>
          <b-col>
            <v-select
              id="lifeRisk"
              v-model="firmStaffRequest.lifeRiskLevel"
              label="riskLevelDescription"
              :options="optionsRisk"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import * as apiRisk from '@/api/tanimlar/VKT/riskLevel'

export default {
  components: {
    vSelect,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    firmStaffRequest: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      optionsRisk: [],
      financialRisk: {},
      legalRisk: {},
      medicalRisk: {},
      lifeRisk: {},
    }
  },
  watch: {
    financialRiskLevel() {
    },
  },
  created() {
    this.getRisk()
  },
  methods: {
    getRisk() {
      apiRisk.GetRisLevels().then(rsk => {
        this.optionsRisk = rsk.data.list
      })
    },
  },
}
</script>
