<template>
  <div>
    <b-form>
      <b-container
        class="bv-example-row"
        name="talep1"
      >
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Personel Sınıfı"
              label-for="staffClass"
            >
              <v-select
                id="staffClass"
                v-model="firmStaffRequest.staffClass"
                label="staffClassDescription"
                :options="optionClass"
                :reduce="item => item.staffClassDescription"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Pozisyon İsmi"
              label-for="position"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="position"
                  v-model="firmStaffRequest.position"
                  placeholder="Pozisyon yazınız"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Pozisyon Faktörü"
              label-for="factorPosition"
            >
              <v-select
                id="factorPositionId"
                v-model="firmStaffRequest.factorPositionId"
                label="staffPositionName"
                :options="optionsPositions"
                :reduce="item => item.staffPositionId"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Kısa Görev Tanımı"
              label-for="shortPositionDescription"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="shortPositionDescription"
                  v-model="firmStaffRequest.shortPositionDescription"
                  placeholder="Görev tanımını yazınız"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Çalışma Şekli"
              label-for="jobType"
            >
              <v-select
                id="jobType"
                v-model="firmStaffRequest.jobType"
                label="jobTypeDescription"
                :options="optionsJobTypes"
                :reduce="item => item.jobTypeDescription"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Alınacak kişi sayısı"
              label-for="personelCount"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="personelCount"
                  v-model="firmStaffRequest.personelCount"
                  type="number"
                  placeholder="Görev tanımını yazınız"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group
              label="Görev Açıklaması"
              label-for="positionDescription"
            >
              <b-form-textarea
                id="positionDescription"
                v-model="firmStaffRequest.positionDescription"
                placeholder="Pozisyon detay açıklamasını yazınız."
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            align-self="center"
          >
            <div class="text-center">
              <b-row>
                <b-col>
                  <label> Talep Durumu </label>
                  <br>
                  <b-badge
                    :variant="durumVar(firmStaffRequest.requestStatus)"
                  >
                    {{ durum(firmStaffRequest.requestStatus) }}
                  </b-badge>
                </b-col>
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    squared
                    variant="primary"
                    :disabled="firmStaffRequest.requestStatus===2"
                    @click="onSaveRequestClick()"
                  >
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"
                    />
                    <span>Talebi Kaydet</span>
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BForm, BContainer,
  BFormInput, BInputGroup, BFormTextarea, BButton, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import * as apiStafPozisitons from '@/api/tanimlar/personelpozisyon'
import * as apiJobType from '@/api/tanimlar/VKT/JobTypes'
import * as apiClass from '@/api/tanimlar/VKT/staffClasses'
import * as apiReq from '@/api/islemler/firmRequest'
import * as apiEval from '@/api/islemler/Evaluation'

import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormTextarea,
    BForm,
    BButton,
    vSelect,
    BContainer,
    BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    firmStaffRequest: {
      type: Object,
      required: true,
      default: null,
    },
    staffQualification: {
      type: Object,
      required: true,
      default: null,
    },
    firmId: {
      type: Number,
      required: true,
      default: -1,
    },
  },
  data() {
    return {
      optionsPositions: [],
      optionsJobTypes: [],
      optionClass: [],
      factorPosition: { },
    }
  },
  computed: {
    ...mapGetters({
      staffRequestCertification: 'staffRequest/GET_FIRMSTAFFREQUEST_CERTIFICATION',
      staffRequestComputer: 'staffRequest/GET_FIRMSTAFFREQUEST_COMPUTER',
      staffRequestForeignLanguage: 'staffRequest/GET_FIRMSTAFFREQUEST_FOREIGNLANGUAGE',
      staffRequestPositionExperience: 'staffRequest/GET_FIRMSTAFFREQUEST_POSITIONEXPERIENCE',
      staffRequestSectorExperience: 'staffRequest/GET_FIRMSTAFFREQUEST_SECTOREXPERIENCE',
    }),
  },
  watch: {
  },
  created() {
    this.getStaffPositions()
    this.getJobTypes()
    this.getClasses()
    this.getFactorPosition()
  },
  methods: {
    ...mapActions({
      saveFirmRequest: 'staffRequest/action_save_firmStaffRequest',
      saveStaffQualification: 'staffRequest/action_save_firmStaffRequestQualification',
      saveStaffCertification: 'staffRequest/action_save_firmStaffRequestCertification',
      saveStaffComputer: 'staffRequest/action_save_firmStaffRequestComputer',
      saveStaffForeignLanguage: 'staffRequest/action_save_firmStaffRequestForeignLanguage',
      saveStaffPositionExperience: 'staffRequest/action_save_firmStaffRequestPositionExperience',
      saveStaffSectorExperience: 'staffRequest/action_save_firmStaffRequestSectorExperience',
    }),
    getStaffPositions() {
      apiStafPozisitons.GetPersonelPozisyonList().then(snf => {
        this.optionsPositions = snf.data.list
      })
    },
    getJobTypes() {
      apiJobType.GetJobTypes().then(res => {
        this.optionsJobTypes = res.data.list
      })
    },
    getClasses() {
      apiClass.GetStaffClasses().then(res => {
        this.optionClass = res.data.list
      })
    },
    getFactorPosition() {
      // this.factorPosition.factorPositionId = this.firmStaffRequest.factorPositionId
    },
    onSaveRequestClick() {
      this.firmStaffRequest.firmInfoId = this.firmId
      if (this.firmStaffRequest.towns === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Talep Kayıt Bilgileri',
            text: 'İlçe Bilgileri Eksik. Kayıt edilemedi.',
            icon: 'PlusSquareIcon',
            variant: 'danger',
          },
        })
      } else if (this.staffQualification.educationLevelId === 0 || this.staffQualification.educationLevelId === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Talep Kayıt Bilgileri',
            text: 'Talep eğitim seviyesi eksik. Kayıt edilemedi.',
            icon: 'PlusSquareIcon',
            variant: 'danger',
          },
        })
      } else if (this.staffQualification.educationImportanceLevel === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Talep Kayıt Bilgileri',
            text: 'Talep eğitim seviyesi gereklilik bilgisi eksik. Kayıt edilemedi.',
            icon: 'PlusSquareIcon',
            variant: 'danger',
          },
        })
      } else {
        this.saveFirmRequest().then(response => {
          const requestId = response
          this.staffQualification.staffRequestId = requestId
          this.staffRequestCertification.staffRequestId = requestId
          this.staffRequestComputer.staffRequestId = requestId
          this.staffRequestForeignLanguage.staffRequestId = requestId
          this.staffRequestPositionExperience.staffRequestId = requestId
          this.staffRequestSectorExperience.staffRequestId = requestId

          this.saveStaffCertification().then(() => {
            this.saveStaffComputer().then(() => {
              this.saveStaffForeignLanguage().then(() => {
                this.saveStaffPositionExperience().then(() => {
                  this.saveStaffQualification().then(() => {
                    this.saveStaffSectorExperience().then(() => {
                      apiReq.CheckValidation(requestId).then(() => {
                        if (this.firmStaffRequest.requestStatus === 1) {
                          apiEval.EvaluateStaffRequest(this.firmStaffRequest.staffRequestId)
                        }
                      })
                    })
                  })
                })
              })
            })
          })
          this.$router.back() // push({ name: 'firm-request' })
        })
      }
    },
    durum(secim) {
      switch (secim) {
        case 0:
          return 'Aktif'
        case 1:
          return 'Eşleştirilmiş'
        case 2:
          return 'Kapatılmış'
        case -1:
          return 'Pasif'
        default:
          return 'Aktif'
      }
    },
    durumVar(secim) {
      switch (secim) {
        case 0:
          return 'primary'
        case 1:
          return 'success'
        case 2:
          return 'dark'
        case -1:
          return 'secondary'
        default:
          return 'primary'
      }
    },
  },
}
</script>

<style lang='scss'>
   @import '@core/scss/vue/libs/vue-select.scss';
</style>
