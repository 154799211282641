<template>
  <div>
    <b-form>
      <b-container
        class="bv-example-row"
        name="talep1"
      >
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Ödenecek Aylık Ücret"
              label-for="position"
            >
              <b-row>
                <b-col cols="6">
                  <b-form-input
                    id="salaryMin"
                    v-model="firmStaffRequest.salaryMin"
                    placeholder="Minimum"
                    type="number"
                  />
                </b-col>
                <b-col cols="6">
                  <b-form-input
                    id="salaryMax"
                    v-model="firmStaffRequest.salaryMax"
                    placeholder="Maksimum"
                    type="number"
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Performans Ödemesi"
              label-for="performanceFee"
            >
              <b-row>
                <b-col cols="6">
                  <b-form-radio
                    v-model="firmStaffRequest.hasPerformanceFee"
                    plain
                    name="performanceFee"
                    :value="true"
                  >
                    Evet
                  </b-form-radio>
                </b-col>
                <b-col cols="6">
                  <b-form-radio
                    v-model="firmStaffRequest.hasPerformanceFee"
                    plain
                    name="performanceFee"
                    :value="false"
                  >
                    Hayır
                  </b-form-radio>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            &nbsp;
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <p class="fs-3">
              Diğer Sosyal İmkanlar
            </p>
            <b-row>
              <b-col
                cols="12"
                md="9"
              >
                <b-form-group>
                  <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="selectedDsi"
                    :options="optionsdsi"
                    name="flavour-1"
                    class="demo-inline-spacing"
                  />
                  <br>
                  <b-form-input
                    id="dsi9"
                    v-model="firmStaffRequest.socialOtherDescription"
                    name="DigerInput"
                    :disabled="!selectedDsi.includes('dsi8')"
                    placeholder="Açıklayınız."
                    size="sm"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>
<script>
import {
  BForm, BContainer, BRow, BCol, BFormInput, BFormGroup, BFormRadio, BFormCheckboxGroup, // BInputGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BForm, BContainer, BRow, BCol, BFormInput, BFormGroup, BFormRadio, BFormCheckboxGroup, // BInputGroup,
  },
  props: {
    firmStaffRequest: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selectedDsi: [],
      optionsdsi: [
        { text: 'İkramiye', value: 'dsi1' },
        { text: 'Çocuk ve Aile yardımı', value: 'dsi2' },
        { text: 'Yakacak yardımı', value: 'dsi3' },
        { text: 'Eğitim yardımı', value: 'dsi4' },
        { text: 'Bireysel emeklilik', value: 'dsi5' },
        { text: 'Hayat sigortası', value: 'dsi6' },
        { text: 'Yok', value: 'dsi7' },
        { text: 'Diğer seçenekleri açıklayınız', value: 'dsi8' },
      ],
    }
  },
  watch: {
    selectedDsi() {
      this.firmStaffRequest.hasSocialBonusPayment = this.selectedDsi.includes('dsi1')
      this.firmStaffRequest.hasSocialChildBenefit = this.selectedDsi.includes('dsi2')
      this.firmStaffRequest.hasSocialHeatingAid = this.selectedDsi.includes('dsi3')
      this.firmStaffRequest.hasSocialEducationalAid = this.selectedDsi.includes('dsi4')
      this.firmStaffRequest.hasSocialIndividualPension = this.selectedDsi.includes('dsi5')
      this.firmStaffRequest.hasSocialLifeInsurance = this.selectedDsi.includes('dsi6')
      this.firmStaffRequest.hasSocialUnavailable = this.selectedDsi.includes('dsi7')
      this.firmStaffRequest.hasSocialOther = this.selectedDsi.includes('dsi8')
    },
  },
  beforeUpdate() {
    this.selectedDsi = []
    if (this.firmStaffRequest.hasSocialBonusPayment) this.selectedDsi.push('dsi1')
    if (this.firmStaffRequest.hasSocialChildBenefit) this.selectedDsi.push('dsi2')
    if (this.firmStaffRequest.hasSocialHeatingAid) this.selectedDsi.push('dsi3')
    if (this.firmStaffRequest.hasSocialEducationalAid) this.selectedDsi.push('dsi4')
    if (this.firmStaffRequest.hasSocialIndividualPension) this.selectedDsi.push('dsi5')
    if (this.firmStaffRequest.hasSocialLifeInsurance) this.selectedDsi.push('dsi6')
    if (this.firmStaffRequest.hasSocialUnavailable) this.selectedDsi.push('dsi7')
    if (this.firmStaffRequest.hasSocialOther) this.selectedDsi.push('dsi8')
  },
  methods: {
  },
}
</script>

<style lang='scss'>
   @import '@core/scss/vue/libs/vue-select.scss';
</style>
