import request from '@/utils/request'

export function GetExpYears() {
  return request({
    url: '/api/ExperienceYears/GetExperienceYears',
    method: 'get',
  })
}

export function GetExpYearsById(data) {
  return request({
    url: `/api/ExperienceYears/GetExperienceYearsById/${data}`,
    method: 'get',
  })
}
