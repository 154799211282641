import request from '@/utils/request'

export function GetEducations() {
  return request({
    url: '/api/EducationLevel/GetEducationLevels',
    method: 'get',
  })
}

export function GetEducationsById(data) {
  return request({
    url: `/api/EducationLevel/GetEducationLevels/${data}`,
    method: 'get',
  })
}
