<template>
  <div>
    <b-form>
      <b-container>
        <!-- Planlama Koordinasyon -->
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Planlama</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="planningAbilityCompetencyLevel"
              v-model="staffQualification.planningAbilityCompetencyLevel"
              label="technicalBasicCompetencyDescription"
              :options="optionsTemelYetenek"
            />
          </b-col>
          <b-col
            cols="6"
            md="2"
          >
            <label>Koordinasyon</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="coordinationAbilityCompetencyLevel"
              v-model="staffQualification.coordinationAbilityCompetencyLevel"
              label="technicalBasicCompetencyDescription"
              :options="optionsTemelYetenek"
            />
          </b-col>
        </b-row>
        <!-- Emir kontrol  -->
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Emir Komuta</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="obeyingAbilityCompetencyLevel"
              v-model="staffQualification.obeyingAbilityCompetencyLevel"
              label="technicalBasicCompetencyDescription"
              :options="optionsTemelYetenek"
            />
          </b-col>
          <b-col
            cols="6"
            md="2"
          >
            <label>Kontrol Denetim</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="controlAuditingAbilityCompetencyLevel"
              v-model="staffQualification.controlAuditingAbilityCompetencyLevel"
              label="technicalBasicCompetencyDescription"
              :options="optionsTemelYetenek"
            />
          </b-col>
        </b-row>
        <!-- Tasarım Temsil  -->
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Tasarım / Kurgu / Hesaplama</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="designAbilityCompetencyLevel"
              v-model="staffQualification.designAbilityCompetencyLevel"
              label="technicalBasicCompetencyDescription"
              :options="optionsTemelYetenek"
            />
          </b-col>
          <b-col
            cols="6"
            md="2"
          >
            <label>Temsil Yeteneği</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="representationAbilityCompetencyLevel"
              v-model="staffQualification.representationAbilityCompetencyLevel"
              label="technicalBasicCompetencyDescription"
              :options="optionsTemelYetenek"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>

<script>
import {
  BForm, BContainer, BRow, BCol, // BFormRadio, BFormInput, BFormCheckboxGroup, BInputGroup, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import * as apiTemelYetenek from '@/api/tanimlar/VKT/temelyetenek'

export default {
  components: {
    vSelect, BRow, BCol, BForm, BContainer,
  },
  directives: {
    Ripple,
  },
  props: {
    staffQualification: {
      type: Object,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      optionsTemelYetenek: [],
    }
  },
  watch: {
    planningAbilityCompetencyLevel() {
      this.staffQualification.planningAbilityCompetencyLevelId = this.staffQualification.planningAbilityCompetencyLevel.technicalBasicCompetencyId
    },
    coordinationAbilityCompetencyLevel() {
      this.staffQualification.coordinationAbilityCompetencyLevelId = this.staffQualification.coordinationAbilityCompetencyLevel.technicalBasicCompetencyId
    },
    obeyingAbilityCompetencyLevel() {
      this.staffQualification.obeyingAbilityCompetencyLevelId = this.staffQualification.obeyingAbilityCompetencyLevel.technicalBasicCompetencyId
    },
    controlAuditingAbilityCompetencyLevel() {
      this.staffQualification.controlAuditingAbilityCompetencyLevelId = this.staffQualification.controlAuditingAbilityCompetencyLevel.technicalBasicCompetencyId
    },
    designAbilityCompetencyLevel() {
      this.staffQualification.designAbilityCompetencyLevelId = this.staffQualification.designAbilityCompetencyLevel.technicalBasicCompetencyId
    },
    representationAbilityCompetencyLevel() {
      this.staffQualification.representationAbilityCompetencyLevelId = this.staffQualification.representationAbilityCompetencyLevel.technicalBasicCompetencyId
    },
  },
  created() {
    this.apiTemelYetenek()
  },
  methods: {
    apiTemelYetenek() {
      apiTemelYetenek.GetTechnicalBasicCompetencies().then(res => {
        this.optionsTemelYetenek = res.data.list
      })
    },
  },
}
</script>
