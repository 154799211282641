import request from '@/utils/request'

export function GetTechnicalBasicCompetencies() {
  return request({
    url: '/api/TechnicalBasicCompetency/GetTechnicalBasicCompetencies',
    method: 'get',
  })
}

export function GetTechnicalBasicCompetenciesById(data) {
  return request({
    url: `/api/TechnicalBasicCompetency/GetTechnicalBasicCompetenciesById/${data}`,
    method: 'get',
  })
}
