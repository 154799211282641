import request from '@/utils/request'

export function GetJobTypes() {
  return request({
    url: '/api/JobType/GetJobTypes',
    method: 'get',
  })
}
export function GetJobType(data) {
  return request({
    url: `/api/JobType/GetJobType/${data}`,
    method: 'get',
  })
}
